.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9vw;
}

.nav i,
.nav h1 {
  float: none;
  display: inline;
  text-align: center;
  font-size: 4vw;
  vertical-align: middle;
}

.nav i {
  position: fixed;
  left: 20px;
}

.nav i:hover {
  cursor: pointer;
}

.nav h5 {
  max-width: 18vw;
  display: inline;
  color: red;
  font-size: 2vw;
  margin-left: 2vw;
  vertical-align: middle;
}

button {
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 4vh;
  padding-top: 2vh;
  transition: all 0.5s ease-in-out;
  transform-origin: left top;
}

.option-group {
  display: block;
  min-width: 30vw;
  margin: 0px 0px;
  text-align: center;
  align-content: center;
}

.option-group label {
  font-size: 2vw;
  display: block;
  color: black;
  /* min-width: 50px; */
  margin-bottom: 1vw;
  text-align: center;
}

.option-group div {
  height: 3vw;
}
.option-group div label {
  height: 3vw;
}
.option-group div label span {
  height: 3vw;
  width: 10vw;
}
.option-group div label input {
  height: 3vw;
  width: 10vw;
}

input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.fab {
  margin: 0px;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed;
}

.urinal {
  height: 14vw;
  width: 7vw;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 10px 10px;
  border: none;
  padding: 5px 5px;
}

.m0 {
  margin: 0;
}
.add-remove {
  display: flex;
  margin: 7px 0;
  border: none;
  color: white;
  padding: 0;
  text-decoration: none;
  text-align: center;
  border-radius: 50%;
  height: 5vw;
  width: 5vw;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.add-remove i {
  font-size: 4vw;
}

/* .settings-button {
  left: 100px;
} */

.not_free_urinal {
  color: black;
  background-color: white;
}

.free_urinal {
  color: white;
  background-color: black;
}

.occupied {
  background-image: url("/images/OccupiedUrinal.png");
}

.empty {
  background-image: url("/images/EmptyUrinal.png");
}

.bad {
  background-image: url("/images/BadUrinal.png");
}

.good {
  background-image: url("/images/GoodUrinal.png");
}

footer {
  position: fixed;
  width: 100vw;
  background-color: white;
  bottom: 0px;
  left: 0px;
  margin: 2vw;
  text-align: center;
}

footer p {
  font-size: 1.5vw;
  margin: 0;
}
